export const LanguageData = {
  options: [
    {
      label: 'English',
      key: 'en',
      default: true
    },
    {
      label: 'Portuguese',
      key: 'pt'
    },
    {
      label: 'Spanish',
      key: 'es'
    }
  ]
}
